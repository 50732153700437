/* eslint-disable camelcase */
import React, { useRef, useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { head } from 'lodash-es'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import classnames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import Seo from '~components/globals/Seo'
import CustomLink from '~utils/CustomLink'
import * as styles from './style.module.scss'
import Container from '~components/globals/Container'

export const Subscribe = ({
  data: {
    allPrismicPage,
    prismicSubscriptionForm: { data },

  },
  location,
}) => {
  // eslint-disable-next-line react/prop-types
  const { consent, cta_text, email_placeholder, headline, image, subline, success_text } = data
  const [validForm, setValidForm] = useState(false)
  const [formSent, setFormSent] = useState(false)
  const [errorText, setErrorText] = useState(false)
  const submitButtonClass = classnames(styles.submit, { [styles.allowed]: validForm })
  const checkbox = useRef(null)
  const emailEl = useRef(null)

  const isDesktop = useMediaQuery({ query: '(min-device-width: 768px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const checkValidity = () => {
    const isValid =
      emailEl.current.value.length > 0 &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailEl.current.value) &&
      checkbox.current.checked

    setValidForm(isValid)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const result = await addToMailchimp(emailEl.current.value)
      console.log(result)
      if (result.result === 'success') {
        setErrorText(false)
        setFormSent(true)
      }
      if (result.result === 'error') {
        setFormSent(false)
        setErrorText(result.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const { sharing_description, sharing_image, sharing_title } = allPrismicPage.edges[0].node.data

  return (
    <div className={styles.Subscribe}>
      <Seo
        title={sharing_title.text}
        description={sharing_description.text}
        url={location.href}
        image={sharing_image.url}
      />
      <Container extraClassName={styles.container}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={image.url} alt={image.alt} />
        </div>
        <div data-html className={styles.textContainer}>
          <div className={styles.title}>{parse(headline.html)}</div>
          <div className={styles.description}>{parse(subline.html)}</div>
          <form className={styles.form} action="" onSubmit={e => handleSubmit(e)}>
            <div className={styles.formGroup}>
              <input
                className={styles.input}
                type="email"
                name="email"
                ref={emailEl}
                id="email"
                onChange={checkValidity}
                required="required"
              />
              <div className={styles.highlight} />
              <label className={styles.label} htmlFor="email">
                {email_placeholder}
              </label>
            </div>
            {isMobile && errorText && <p className={styles.error}>{parse(errorText)}</p>}
            {isMobile && (
              <div className={styles.success}>
                <p className={classnames(styles.successCopy, { [styles.visible]: formSent })}>{success_text}</p>
              </div>
            )}
            <div className={styles.conditionsGroup}>
              <input
                className={styles.checkbox}
                id="data"
                required="required"
                onChange={checkValidity}
                type="checkbox"
                ref={checkbox}
              />
              <label data-html className={styles.checkboxLabel} htmlFor="data">
                {parse(consent.html)}
              </label>
            </div>
            <button className={submitButtonClass} type="submit">
              {cta_text}
            </button>
            {isDesktop && errorText && <div className={styles.error}>{parse(errorText)}</div>}
          </form>
          {isDesktop && (
            <div className={styles.successContainer}>
              <div className={styles.success}>
                <div className={classnames(styles.successCopy, { [styles.visible]: formSent })}>{success_text}</div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Subscribe

export const pageQuery = graphql`
  query {
    allPrismicPage(filter: { data: { slug: { eq: "subscribe" } } }) {
      edges {
        node {
          id
          data {
            sharing_description {
              html
              text
            }
            sharing_image {
              alt
              url
            }
            sharing_title {
              html
              text
            }
          }
        }
      }
    }
    prismicSubscriptionForm {
      data {
        consent {
          html
        }
        cta_text
        email_placeholder
        headline {
          html
        }
        image {
          alt
          url
        }
        subline {
          html
        }
        success_text
      }
    }
  }
`
Subscribe.propTypes = {
  data: PropTypes.shape({
    prismicSubscriptionForm: PropTypes.shape({
      data: PropTypes.shape({
        headline: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
          }),
        ),
        subline: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
          }),
        ),
        cta_text: PropTypes.string.isRequired,
        email_placeholder: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
        success_text: PropTypes.string.isRequired,
      }),
    }),
    prismicPage: PropTypes.shape({
      data: PropTypes.shape({
        sharing_title: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
        sharing_description: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
        sharing_image: PropTypes.shape({ url: PropTypes.string }),
      }),
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
}
